import React from "react";
import { Switch, Route } from "react-router-dom";

import "./common.css";

import Home from "../home/home";
import SignUp from "../register/signup";
import SignIn from "../register/signin";
import Profile from "../register/profile";
import About from "../about/about";
import Contact from "../contact/contact";
import Detail from "../detail/detail";

const Routers = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/profile" component={Profile} />
      <Route path="/signup" component={SignUp} />
      <Route path="/signin" component={SignIn} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/detail/:id" component={Detail} />
    </Switch>
  );
};

export default Routers;
