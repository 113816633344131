import React, { useState } from "react";
import { getUserProfile, updateProfile } from "../schema/schema";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "../common/loader";
import "./register.css";

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

const SignUp = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [statusText, setStatus] = useState("");
  const [errorText, setError] = useState("");
  /* const [columns, setColumn] = useState({}); */
  const [errors, setErrors] = useState({});

  const [columns, setColumn] = useState({
    type: "Customer",
    fullname: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    let fields = columns;
    let errors = {};
    let formIsValid = true;

    if (!fields["fullname"] && !fields["firstname"] && !fields["lastname"]) {
      formIsValid = false;
      setError("Enter Fullname or FirstName and LastName");
    } else if (
      !fields["fullname"] &&
      !fields["firstname"] &&
      fields["lastname"]
    ) {
      formIsValid = false;
      errors["firstname"] = "Firstname should not be blank";
    } else if (
      !fields["fullname"] &&
      fields["firstname"] &&
      !fields["lastname"]
    ) {
      formIsValid = false;
      errors["lastname"] = "Lastname should not be blank";
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone should be blank";
    }
    if (!fields["zip"]) {
      formIsValid = false;
      errors["zip"] = "Zipcode should be blank";
    }
    setErrors(errors);

    if (formIsValid) {
      setStatus("");
      setError("");
      setLoaderStatus(true);
      doUpdate();
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let fields = columns;
    fields[name] = value;
    removeItem(name);
    setColumn({
      ...columns,
      [name]: value,
    });
  };

  const removeItem = (name) => {
    let arrays = {};
    Object.keys(errors).map((item) => {
      if (item !== name) arrays[item] = errors[item];
    });
    setErrors(arrays);
  };

  const [doUpdate] = useMutation(updateProfile(columns), {
    skip: false,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onCompleted(data) {
      setStatus("");
      setError("");
      setLoaderStatus(false);
      let upRes = data.UpdateProfile;
      if (upRes.status === 200) {
        setStatus(upRes.statusText);
      } else {
        setErrors(upRes.statusText);
      }
    },
  });

  const { data: resInit, error: errInit } = useQuery(
    getUserProfile({ id: sessionStorage.getItem("userid"), email: null }),
    {
      skip: false,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      onCompleted: () => doRetrieved(),
    }
  );

  const doRetrieved = () => {
    let data = resInit.User;
    if (data.status === 200) {
      data = data.data;
      setColumn(data);
    }
  };

  return (
    <main>
      <Loader show={loaderStatus} />
      <div className="wrapper">
        <div className="form-wrapper">
          <h2>Update Profile</h2>
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="column">
                <div className="type">
                  <label htmlFor="type">Type of Customer</label>
                  <select
                    name="type"
                    defaultValue="Customer"
                    /* value={columns.type || "Customer"} */
                    onChange={handleChange}
                    noValidate
                  >
                    <option value="Customer">Customer</option>
                    <option value="Delivery">Delivery Partner</option>
                    <option value="Resturant">Resturant</option>
                  </select>
                </div>
                <div className="fullName">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    value={columns.firstname || ""}
                    autoComplete="off"
                    type="firstname"
                    name="firstname"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["firstname"] && (
                    <span className="error">{errors["firstname"]}</span>
                  )}
                </div>
                <div className="phone">
                  <label htmlFor="phone">Phone</label>
                  <input
                    value={columns.phone || ""}
                    autoComplete="off"
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["phone"] && (
                    <span className="error">{errors["phone"]}</span>
                  )}
                </div>
                <div className="address">
                  <label htmlFor="address1">Address</label>
                  <input
                    value={columns.address1 || ""}
                    autoComplete="off"
                    type="text"
                    name="address1"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
                <div className="state">
                  <label htmlFor="state">State</label>
                  <input
                    value={columns.state || ""}
                    autoComplete="off"
                    type="text"
                    name="state"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
              </div>
              <div className="column">
                <div className="fullName">
                  <label htmlFor="fullname">Full Name</label>
                  <input
                    value={columns.fullname || ""}
                    autoComplete="off"
                    type="text"
                    name="fullname"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["fullname"] && (
                    <span className="error">{errors["fullname"]}</span>
                  )}
                </div>

                <div className="fullName">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    value={columns.lastname || ""}
                    autoComplete="off"
                    type="lastname"
                    name="lastname"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["lastname"] && (
                    <span className="error">{errors["lastname"]}</span>
                  )}
                </div>
                <div className="zip">
                  <label htmlFor="zip">Zip Code</label>
                  <input
                    value={columns.zip || ""}
                    autoComplete="off"
                    type="text"
                    name="zip"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["zip"] && (
                    <span className="error">{errors["zip"]}</span>
                  )}
                </div>
                <div className="city">
                  <label htmlFor="city">City</label>
                  <input
                    value={columns.city || ""}
                    autoComplete="off"
                    type="text"
                    name="city"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
                <div className="country">
                  <label htmlFor="country">Country</label>
                  <input
                    value={columns.country || ""}
                    autoComplete="off"
                    type="text"
                    name="country"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
              </div>
            </div>
            {statusText && statusText.length > 0 && (
              <span className="status">{statusText}</span>
            )}
            {errorText && errorText.length > 0 && (
              <span className="errorBold">{errorText}</span>
            )}
            <div className="submit">
              <button>Update</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default SignUp;
