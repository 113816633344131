import React, { useState } from "react";
import { singupUser } from "../schema/schema";
import { useMutation } from "@apollo/react-hooks";
import Loader from "../common/loader";
import "./register.css";

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

const SignUp = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [statusText, setStatus] = useState("");
  const [errorText, setError] = useState("");
  const [columns, setColumn] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    let fields = columns;
    let errors = {};
    let formIsValid = true;

    if (!fields["fullname"] && !fields["firstname"] && !fields["lastname"]) {
      formIsValid = false;
      setError("Enter Fullname or FirstName and LastName");
    } else if (
      !fields["fullname"] &&
      !fields["firstname"] &&
      fields["lastname"]
    ) {
      formIsValid = false;
      errors["firstname"] = "Firstname should not be blank";
    } else if (
      !fields["fullname"] &&
      fields["firstname"] &&
      !fields["lastname"]
    ) {
      formIsValid = false;
      errors["lastname"] = "Lastname should not be blank";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email should be blank";
    } else if (!validEmailRegex.test(fields["email"])) {
      errors["email"] = "Email is not valid!";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password should be blank";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone should be blank";
    }
    if (!fields["zip"]) {
      formIsValid = false;
      errors["zip"] = "Zipcode should be blank";
    }
    setErrors(errors);

    if (formIsValid) {
      setStatus("");
      setError("");
      setLoaderStatus(true);
      doRegistration();
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let fields = columns;
    fields[name] = value;
    removeItem(name);
    setColumn(fields);
  };

  const removeItem = (name) => {
    let arrays = {};
    Object.keys(errors).map((item) => {
      if (item !== name) arrays[item] = errors[item];
    });
    setErrors(arrays);
  };

  const [doRegistration, { called, data }] = useMutation(singupUser(columns), {
    fetchPolicy: "no-cache",
  });

  if (called && data && data.SignUp) {
    let signup = data.SignUp;
    if (signup.status === 400) {
      if (signup.statusText !== errorText) {
        setLoaderStatus(false);
        setError(signup.statusText);
      }
    } else {
      if (signup.statusText !== statusText) {
        setLoaderStatus(false);
        setStatus(signup.statusText);
      }
    }
  }

  return (
    <main>
      <Loader show={loaderStatus} />
      <div className="wrapper">
        <div className="form-wrapper">
          <h2>Create New Account</h2>
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="column">
                <div className="type">
                  <label htmlFor="type">Type of Customer</label>
                  <select
                    name="type"
                    defaultValue="Customer"
                    onChange={handleChange}
                    noValidate
                  >
                    <option value="Customer">Customer</option>
                    <option value="Delivery">Delivery Partner</option>
                    <option value="Resturant">Resturant</option>
                  </select>
                </div>
                <div className="fullName">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    autoComplete="off"
                    type="firstname"
                    name="firstname"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["firstname"] && (
                    <span className="error">{errors["firstname"]}</span>
                  )}
                </div>
                <div className="email">
                  <label htmlFor="email">Email</label>
                  <input
                    autoComplete="off"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["email"] && (
                    <span className="error">{errors["email"]}</span>
                  )}
                </div>
                <div className="phone">
                  <label htmlFor="phone">Phone</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["phone"] && (
                    <span className="error">{errors["phone"]}</span>
                  )}
                </div>
                <div className="address">
                  <label htmlFor="address1">Address</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="address1"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
                <div className="state">
                  <label htmlFor="state">State</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="state"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
              </div>
              <div className="column">
                <div className="fullName">
                  <label htmlFor="fullname">Full Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="fullname"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["fullname"] && (
                    <span className="error">{errors["fullname"]}</span>
                  )}
                </div>

                <div className="fullName">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    autoComplete="off"
                    type="lastname"
                    name="lastname"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["lastname"] && (
                    <span className="error">{errors["lastname"]}</span>
                  )}
                </div>
                <div className="password">
                  <label htmlFor="password">Password</label>
                  <input
                    autoComplete="off"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["password"] && (
                    <span className="error">{errors["password"]}</span>
                  )}
                </div>
                <div className="zip">
                  <label htmlFor="zip">Zip Code</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="zip"
                    onChange={handleChange}
                    noValidate
                  />
                  {errors["zip"] && (
                    <span className="error">{errors["zip"]}</span>
                  )}
                </div>
                <div className="city">
                  <label htmlFor="city">City</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="city"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
                <div className="country">
                  <label htmlFor="country">Country</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="country"
                    onChange={handleChange}
                    noValidate
                  />
                </div>
              </div>
            </div>
            {statusText && statusText.length > 0 && (
              <span className="status">{statusText}</span>
            )}
            {errorText && errorText.length > 0 && (
              <span className="errorBold">{errorText}</span>
            )}
            <div className="submit">
              <button>Create</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default SignUp;
