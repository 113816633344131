import React, { useState } from "react";
import Loader from "../common/loader";
import { loginUser } from "../schema/schema";
import { useLazyQuery } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";

import "./register.css";

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

const SignIn = ({ navigate }) => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [statusText, setStatus] = useState("");
  const [errorText, setError] = useState("");

  const [columns, setColumn] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    let fields = columns;
    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email should be blank";
    } else if (!validEmailRegex.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "Email is not valid!";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password should be blank";
    }

    setErrors(errors);

    if (formIsValid) {
      setStatus("");
      setError("");
      setLoaderStatus(true);
      doLogin();
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let fields = columns;
    fields[name] = value;
    removeItem(name);
    setColumn(fields);
  };

  const removeItem = (name) => {
    let arrays = {};
    Object.keys(errors).map((item) => {
      if (item !== name) arrays[item] = errors[item];
    });
    setErrors(arrays);
  };

  const [doLogin, { called, data }] = useLazyQuery(loginUser(columns), {
    fetchPolicy: "no-cache",
  });

  if (called && data && data.SignIn) {
    let login = data.SignIn;
    if (login.status === 200 && login.statusText === "Ok") {
      if (login.statusText !== statusText) {
        sessionStorage.setItem("access_token", login.data.access_token);
        sessionStorage.setItem("profilename", login.data.name);
        sessionStorage.setItem("userid", login.data.id);
        return <Redirect to="/" />;
        /* setLoaderStatus(false);
        setStatus(login.statusText); */
      }
    } else {
      if (login.statusText !== errorText) {
        setLoaderStatus(false);
        setError(login.statusText);
      }
    }
  }

  return (
    <main>
      <Loader show={loaderStatus} />
      <div className="wrapper">
        <div className="signin-form-wrapper">
          <h2>Login to Account</h2>
          <br />
          <form onSubmit={handleSubmit} style={{ marginTop: 20 }} noValidate>
            <div className="email">
              <label htmlFor="email">Email</label>
              <input
                autoComplete="Off"
                type="email"
                name="email"
                onChange={handleChange}
                noValidate
              />
              {errors["email"] && (
                <span className="error">{errors["email"]}</span>
              )}
            </div>
            <div className="password">
              <label htmlFor="password">Password</label>
              <input
                autoComplete="Off"
                type="password"
                name="password"
                onChange={handleChange}
                noValidate
              />
              {errors["password"] && (
                <span className="error">{errors["password"]}</span>
              )}
            </div>
            {statusText && statusText.length > 0 && (
              <span className="status">{statusText}</span>
            )}
            {errorText && errorText.length > 0 && (
              <span className="errorBold">{errorText}</span>
            )}
            <div className="submit">
              <button>Login</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default SignIn;
