import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <main>
      <h1>Contact page</h1>
    </main>
  );
};

export default Contact;
