import React, { useEffect, useState } from "react";
import "./detail.css";

const Detail = ({ match }) => {
  const [item, setItem] = useState({});

  useEffect(() => {
    fetchItems();
  });

  const fetchItems = async () => {
    const data = await fetch(
      `https://jsonplaceholder.typicode.com/todos/${match.params.id}`
    );
    const item = await data.json();
    setItem(item);
  };

  return (
    <main>
      <h1>Details</h1>
      <ul>
        <li> Id: {item.id}</li>
        <li> UserId: {item.userId}</li>
        <li> Title: {item.title}</li>
        <li> Completed: {item.completed}</li>
      </ul>
    </main>
  );
};

export default Detail;
