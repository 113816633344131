import React from "react";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { defaultFeeds, subscriptionFeeds } from "../schema/schema";
import "./home.css";

let rowItems = [];

const addToList = (data) => {
  if (data && rowItems != null) {
    let feeds = data.Feed.data;
    if (feeds.count === undefined || feeds.length === undefined) {
      let arr = [];
      arr.push(feeds);
      feeds = arr;
    }
    if (feeds) {
      feeds.map((item) => {
        let keyItem = rowItems.findIndex((x) => x.ID === item.ID);
        if (keyItem === -1) rowItems.splice(0, 0, item);
      });
    }
  }
};

const Home = () => {
  const { data } = useQuery(defaultFeeds());
  const { data: dataS } = useSubscription(subscriptionFeeds());

  if (data) addToList(data);
  if (dataS) addToList(dataS);

  return (
    <main>
      <table id="customers">
        <tbody>
          <tr>
            <th>Provider ID</th>
            <th>Provider Name</th>
            <th>Merchant Name</th>
            <th>Merchant Area</th>
            <th>Delivery Area</th>
            {/*   <th>
              Merchant <br />
              Location
            </th> */}
            {/*   <th>
              Delivery <br />
              Address
            </th> */}
            {/*    <th>
              Delivery <br />
              Location
            </th> */}
            <th>Order Time</th>
            <th>Offer (Rs)</th>
            <th>Offer (%)</th>
          </tr>
          {rowItems.length === 0 && (
            <tr>
              <td
                colSpan="8"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                No Data found...!
              </td>
            </tr>
          )}
          {rowItems.map((item) => (
            <tr key={item.ID}>
              {/* <td>
                <Link to={`/detail/${item.id}`}>{item.id}</Link>
              </td> */}
              <td>{item.SPID}</td>
              <td>{item.SPN}</td>
              <td>{item.MN}</td>
              <td>{item.MArea}</td>
              <td>{item.DLArea}</td>
              {/* <td>{item.MLGC1}</td> */}
              {/*  <td>{item.DLA}</td> */}
              {/* <td>{item.DLGC1}</td> */}
              <td>Expires in {item.OTO}</td>
              <td>{item.SPDRS}</td>
              <td>{item.SPDPA}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

export default Home;
