import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./common.css";

const standardItems = [
  { name: "/", title: "Home" },
  { name: "/signup", title: "Register" },
  { name: "/signin", title: "Sign In" },
  { name: "/about", title: "About" },
  { name: "/contact", title: "Contact" },
];

const sessionItems = [
  { name: "/", title: "Home" },
  { name: "/profile", title: "Profile" },
  { name: "/signout", title: "Sign Out" },
  { name: "/about", title: "About" },
  { name: "/contact", title: "Contact" },
];

const IsLoggedIn = () => {
  let token = sessionStorage.getItem("access_token") || null;
  if (token && token.length > 0) {
    return true;
  }
  return false;
};

const signOut = () => {
  sessionStorage.removeItem("access_token");
  window.location.reload(false);
};

const getLink = (pathname, item, index) => {
  if (item.name === "/signout") {
    return (
      <div className="divlink" onClick={() => signOut()} key={index}>
        {item.title}
      </div>
    );
  } else {
    return (
      <Link
        key={index}
        className={pathname === item.name ? "active" : ""}
        to={item.name}
      >
        {item.title}
      </Link>
    );
  }
};

const Header = () => {
  const { pathname } = useLocation();
  const menuItems = IsLoggedIn() ? sessionItems : standardItems;
  return (
    <header>
      <div className="topnav" id="myTopnav">
        {menuItems.map((item, index) => getLink(pathname, item, index))}
      </div>
    </header>
  );
};

export default Header;
