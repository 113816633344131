import React from "react";
import "./about.css";

const About = () => {
  return (
    <main>
      <h1>About page</h1>
    </main>
  );
};

export default About;
