import React from "react";
import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";

import Header from "./common/header";
import Footer from "./common/footer";
import Routers from "./common/routers";
/*import Home from "./home/home";
import SignUp from "./register/signup";
import SignIn from "./register/signin";
import About from "./about/about";
import Contact from "./contact/contact";*/

const App = () => {
  return (
    <Router>
      <div className="container">
        <Header />
        <Routers />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
