import gql from "graphql-tag";

/* const feed = gql`
    ID
    SPID
    SPN
    SPAPP
    MN
    MC
    MArea
    MLGC1
    MLGC2
    MLA
    OTO
    DLC
    DLArea
    DLA
    DLGC1
    DLGC2
    SPDRS
    SPDPA
`; */

function getQuery(input, noNulls) {
  let query = "";
  Object.getOwnPropertyNames(input).forEach(function (val, idx, array) {
    let addNulls = true;
    let inVal = input[val] || null;
    if (inVal !== null && inVal !== undefined && inVal !== "") {
      inVal = '"' + input[val].replace(/"/g, '\\"') + '"';
    } else {
      if (noNulls) addNulls = false;
    }

    if (addNulls) {
      let item = val + ": " + inVal;
      if (query === "") {
        query = item;
      } else {
        query = query + "\n" + item;
      }
    }
  });
  return query;
}

const singupUser = (input) => {
  let query = getQuery(input, true);

  return gql`
    mutation {
      SignUp(
        input: { ${query} }
      ) {
        status
        statusText
      }
    }
  `;
};

const loginUser = (input) => {
  let query = getQuery(input, false);

  return gql`
    query loginUser {
      SignIn ( ${query} ) {
        status
        statusText
        data {
          id
          name
          access_token
        }
      }
    }
  `;
};

const getUserProfile = (input) => {
  let query = getQuery(input, false);

  return gql`
    query GetUserInfo {
      User(${query}) {
        status
        statusText
        data {
          type
          fullname
          firstname
          lastname
          email
          phone
          address1
          address2
          city
          state
          zip
          country
        }
      }
    }
  `;
};

const updateProfile = (input) => {
  let query = getQuery(input, true);

  return gql`
    mutation {
      UpdateProfile ( 
        input: { ${query} }
      ) {
        status
        statusText
      }
    }
  `;
};

const defaultFeeds = () => {
  return gql`
    query {
      Feed {
        status
        statusText
        data {
          ID
          SPID
          SPN
          SPAPP
          MN
          MC
          MArea
          MLGC1
          MLGC2
          MLA
          OTO
          DLC
          DLArea
          DLA
          DLGC1
          DLGC2
          SPDRS
          SPDPA
        }
      }
    }
  `;
};

const subscriptionFeeds = () => {
  return gql`
    subscription OnFeederAdded {
      Feed {
        status
        statusText
        data {
          ID
          SPID
          SPN
          SPAPP
          MN
          MC
          MArea
          MLGC1
          MLGC2
          MLA
          OTO
          DLC
          DLArea
          DLA
          DLGC1
          DLGC2
          SPDRS
          SPDPA
        }
      }
    }
  `;
};

const addFeed = (input) => {
  let query = "";

  Object.getOwnPropertyNames(input).forEach(function (val, idx, array) {
    let item = val + ': "' + input[val].replace(/"/g, '\\"') + '"';
    if (query === "") query = item;
    else query = query + "\n" + item;
  });

  return gql`
    mutation addFeed {
      feed(input: { ${query} }) {
        status
        statusText
        data {
          ID
        }
      }
    }
  `;
};

export {
  singupUser,
  loginUser,
  defaultFeeds,
  addFeed,
  subscriptionFeeds,
  getUserProfile,
  updateProfile,
};
